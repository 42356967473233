import { compose, getLastStringFromUrl } from "src/helpers";
import { useLazyQuery } from "@apollo/client";
import { checkBlockValid } from "src/modules/worksheet/components/blocks";
import {
    WORKSHEET_SYNC_BLOCKS_QUERY,
    INSERT_SINGLE_WORKSHEET_BLOCK_MAP,
    GET_BLOCK_QUERY,
    SYNC_BLOCK_SEARCH_QUERY,
} from "../../graphql";
import { useMutation } from "@apollo/client";
import {
    withDeleteBlocks,
    withDeleteWorksheetBlockMap,
    withHookForWorksheet,
    withWorksheetStats,
    withHookForWorksheetStories,
    withInsertWorksheetBlockMap,
    withUpdateWorksheet,
    withUpdateWorksheetUserStats,
    withVersionModelMap,
    withWorksheetBlocks,
    withHookForWorksheetAudios,
    withUpdateWorksheetBlock,
    withInsertWorksheetBlock,
    withWorksheetPublishedBlocks,
} from "src/modules/worksheet/operations";
import { message, Modal, Typography } from "antd";
import _ from "lodash";

import { useRouter } from "src/helpers";
import { useEffect, useState } from "react";
import CreateView from "../../components/WorksheetEditor";
import { useAuth0 } from "@auth0/auth0-react";
import {
    generateAudios,
    addGenerateLogicForVarAudio,
} from "../../components/WorksheetEditor/helpers/generateAudios";
import axios from "axios";
import { captureException } from "@sentry/react";
import {
    graphQLClientUS,
    publishToSecondaryHasuraEndpoints,
} from "../../helpers/publishToSecondaryHasuraEndpoints";
import { WORKSHEET_STATS } from "../../graphql";

const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getV4WorksheetBlockMapObject = ({
    blockData,
    worksheetData,
}: any) => {
    return blockData.map((block: any, idx: number) => {
        const { worksheet_block_map_id } = block;
        const children = block?.children?.length
            ? {
                  children: {
                      data: block.children?.map((child: any, index: number) => {
                          const gChildren = child?.children?.length
                              ? {
                                    children: {
                                        data: child.children?.map(
                                            (child: any, index: number) => {
                                                return {
                                                    ..._.omit(child, [
                                                        "tmpId",
                                                        "__typename",
                                                        "tags",
                                                        "children",
                                                    ]),
                                                    order: index + 1,
                                                };
                                            },
                                        ),
                                        on_conflict: {
                                            constraint: "block_pkey",
                                            update_columns: [
                                                "data",
                                                "type",
                                                "order",
                                                "backend",
                                            ],
                                        },
                                    },
                                }
                              : {};

                          return {
                              ...gChildren,
                              ..._.omit(child, [
                                  "tmpId",
                                  "__typename",
                                  "tags",
                                  "children",
                              ]),
                              order: index + 1,
                          };
                      }),
                      on_conflict: {
                          constraint: "block_pkey",
                          update_columns: ["data", "type", "order", "backend"],
                      },
                  },
              }
            : {};

        return {
            ...(worksheet_block_map_id ? { id: worksheet_block_map_id } : {}),
            order: idx + 1,
            ...worksheetData,
            block: {
                data: {
                    ...children,
                    ..._.omit(block, [
                        "tmpId",
                        "worksheet_block_map_id",
                        "__typename",
                        "children",
                        "tags",
                    ]),
                },
                on_conflict: {
                    constraint: "block_pkey",
                    update_columns: ["data", "type", "backend"],
                },
            },
        };
    });
};

export const getWorksheetBlockIdMap = ({ blockData, worksheetData }: any) => {
    return blockData.map((block: any, idx: number) => {
        const { worksheet_block_map_id, levelId } = block;

        return {
            ...(worksheet_block_map_id ? { id: worksheet_block_map_id } : {}),
            order: idx + 1,
            ...worksheetData,
            ...(levelId
                ? { block_id: levelId }
                : {
                      block: {
                          data: block,
                          on_conflict: {
                              constraint: "block_pkey",
                              update_columns: ["data", "type", "backend"],
                          },
                      },
                  }),
        };
    });
};

export const getV4ParsedBlocks = (blocksArray: any) => {
    return blocksArray
        ?.slice()
        .sort(function (a: any, b: any) {
            return a.order - b.order;
        })
        .map(({ id: worksheet_block_map_id, block: level }: any) => ({
            ...level,
            children: level.children
                ?.slice()
                ?.sort(function (a: any, b: any) {
                    return a.order - b.order;
                })
                ?.map((chunk: any) => ({
                    ...chunk,
                    children: chunk.children
                        ?.slice()
                        ?.sort(function (a: any, b: any) {
                            return a.order - b.order;
                        }),
                })),
            worksheet_block_map_id,
        }));
};

function createReferenceIdMap(blocks: any[]) {
    const referenceIdMap: any = {};

    function traverseBlock(block: any) {
        if (block.reference_id !== null) {
            referenceIdMap[block.reference_id] = block.id;
        }
        if (block.children) {
            block.children.forEach(traverseBlock);
        }
    }

    blocks.forEach((block) => traverseBlock(block?.block));
    return referenceIdMap;
}

const WorksheetEdit = (props) => {
    const {
        updateWorksheet,
        worksheetBlockMap,
        refetchWorksheetBlockMap,
        loadingWorksheetMap,
        insertWorksheetBlockMap,
        deleteWorksheetBlockMap,
        deleteBlocks,
        worksheet,
        versionModelMap,
        insertWorksheetBlock,
        updateWorksheetBlock,
        updateWorksheetUserStats,

        storyJobs,
        storyJobsLoading,
        audioJobs,
        audioJobsLoading,

        refetchWorksheetStats,
        worksheetStatsLoading,
        worksheetStats,
        loadingPublishedBlocks,
        publlishedBlocks,
        refetchPublishedBlocks,
    } = props;
    const router = useRouter();

    const { user } = useAuth0();

    let worksheet_id = worksheet?.id;
    const { redirect_to } = router.query;

    const [isSaving, toggleSaving] = useState(false);
    const [shouldBlockUI, toggleShouldBlockUI] = useState(false);

    const [currentBlock, setCurrentBlock] = useState<any>(null);
    const [currentSubBlock, setCurrentSubBlock] = useState<any>(null);
    const [currentChunkBlock, setCurrentChunkBlock] = useState<any>(null);

    const [hasChanged, toggleHasChanged] = useState(false);
    const [lastSavedAt, setLastSavedAt] = useState<any>(null);
    const [blocks, setBlocks] = useState<any>([]);

    const [isOpen, setIsOpen] = useState(false);
    const [saveVal, setSaveVal] = useState(false);
    const [worksheetStatsState, setWorksheetStatsState] = useState<any>({});
    const [currentSavedCount, setCurrentSavedCount] = useState<any>(0);
    const [publishedBlocksMap, setPublishedBlocksMap] = useState<any>({});
    const [worksheetWorksheetStatsUS, setWorksheetWorksheetStatsUS] =
        useState<any>({});
    const [getSyncBlocks, { data }] = useLazyQuery(SYNC_BLOCK_SEARCH_QUERY);
    const [syncBlocks, setSyncBlocks] = useState([]);
    useEffect(() => {
        getSyncBlocks();
    }, []); // Only runs if getSyncBlocks or localSelectedFlag changes

    useEffect(() => {
        if (data?.blocks) {
            setSyncBlocks(data.blocks); // Set syncBlocks state when data is available
        }
    }, [data]); // Runs when data changes

    useEffect(() => {
        console.log("sync blocks", syncBlocks);
    }, [syncBlocks]); // Runs when data changes

    const [getBlock] = useLazyQuery(GET_BLOCK_QUERY, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (worksheet && worksheet?.type !== "personalized_learning_v4") {
            let query = router.query;
            delete query.worksheet_id;
            router.push({
                pathname: `/worksheet/update_enhanced/${worksheet?.id}`,
                query,
            });
        }
    }, [worksheet, router]);

    useEffect(() => {
        if (
            !worksheetStatsLoading &&
            worksheetStats &&
            !worksheetStatsState?.other
        ) {
            setWorksheetStatsState(worksheetStats);
            setCurrentSavedCount(worksheetStats?.other?.save_count);
        }
    }, [worksheetStatsLoading, worksheetStats]);

    useEffect(() => {
        if (!loadingPublishedBlocks) {
            setPublishedBlocksMap(createReferenceIdMap(publlishedBlocks));
        }
    }, [loadingPublishedBlocks, publlishedBlocks]);

    useEffect(() => {
        const processLevelBlocks = (levelBlocks, syncBlocks) => {
            // Traverse function to recursively process each object
            const traverse = (obj) => {
                if (Array.isArray(obj)) {
                    return obj.map(traverse); // Return a new array for processing children
                } else if (obj && typeof obj === "object") {
                    // Check if the object is a "chunk" with children
                    if (obj.type === "chunk" && Array.isArray(obj.children)) {
                        // Process children and create a new children array
                        const updatedChildren = obj.children.map(
                            (child, index) => {
                                if (child?.data && child.data.id) {
                                    const childId = child.data.id;

                                    // Find matching sync block by ID
                                    const matchingSyncBlock = syncBlocks.find(
                                        (syncBlock) => syncBlock.id === childId,
                                    );

                                    if (matchingSyncBlock) {
                                        // Apply changes function
                                        const applyChanges = (
                                            block,
                                            changesList,
                                        ) => {
                                            if (
                                                !changesList ||
                                                typeof changesList !== "object"
                                            ) {
                                                return block; // Return the block unchanged if changesList is invalid
                                            }

                                            // Create a deep copy of the block to avoid mutating the original
                                            const mutableBlock =
                                                structuredClone(block);

                                            Object.entries(changesList).forEach(
                                                ([key, value]) => {
                                                    const keys = key.split(".");
                                                    let target = mutableBlock;

                                                    // Traverse to the target property, creating nested objects if necessary
                                                    keys.slice(0, -1).forEach(
                                                        (nestedKey) => {
                                                            if (
                                                                typeof target[
                                                                    nestedKey
                                                                ] !==
                                                                    "object" ||
                                                                target[
                                                                    nestedKey
                                                                ] === null
                                                            ) {
                                                                target[
                                                                    nestedKey
                                                                ] = {}; // Initialize as object if undefined or null
                                                            }
                                                            target =
                                                                target[
                                                                    nestedKey
                                                                ]; // Move to the next level
                                                        },
                                                    );

                                                    // Apply the final value at the last key
                                                    const finalKey =
                                                        keys[keys.length - 1];
                                                    target[finalKey] = value;
                                                },
                                            );

                                            return mutableBlock;
                                        };

                                        const updatedChild = applyChanges(
                                            matchingSyncBlock,
                                            child?.data?.changes_list,
                                        );

                                        // Create a new child object with the updated data and other properties
                                        return {
                                            ...child,
                                            data: {
                                                ...updatedChild.data,
                                                changes_list:
                                                    child.data.changes_list,
                                            },
                                            backend: {
                                                ...updatedChild.backend,
                                            },
                                            is_modified: true,
                                            master_block: updatedChild.id,
                                        };
                                    } else {
                                        console.log(
                                            `No matching sync block found for child ID: ${childId}`,
                                        );
                                    }
                                }
                                // Return the child unmodified if no matching block is found
                                return child;
                            },
                        );

                        // Return a new object with updated children
                        return {
                            ...obj,
                            children: updatedChildren,
                        };
                    }

                    // Recursively process other properties of the object if necessary
                    return Object.fromEntries(
                        Object.entries(obj).map(([key, value]) => [
                            key,
                            traverse(value),
                        ]),
                    );
                }
                // Return primitive values as is
                return obj;
            };

            // Start traversal from the root object

            const result = traverse(levelBlocks);

            // Return the updated levelBlocks after processing
            return result;
        };

        let updatedWorksheetBlockMap = processLevelBlocks(
            worksheetBlockMap,
            syncBlocks,
        );
        setParsedBlocks(updatedWorksheetBlockMap);
    }, [worksheetBlockMap]);

    useEffect(() => {
        // Every 10s, run a mutation to tell the backend that you're online
        let onlineIndicator: string | number | NodeJS.Timeout | undefined;
        let v = setTimeout(() => {
            updateLastSeen();
            onlineIndicator = setInterval(() => updateLastSeen(), 10000);
        }, 2000);
        graphQLClientUS
            .request(WORKSHEET_STATS, {
                worksheet_id: getLastStringFromUrl(),
            })
            .then(({ worksheet_worksheet_stats }: any) => {
                setWorksheetWorksheetStatsUS(
                    worksheet_worksheet_stats?.[0] ?? {},
                );
            });

        return () => {
            // Clean up
            onlineIndicator && clearInterval(onlineIndicator);
            clearTimeout(v);
        };
    }, []);

    useEffect(() => {
        if (saveVal) {
            onSave({});
            setSaveVal(false);
        }
    }, [saveVal]);

    useEffect(() => {
        if (!storyJobsLoading && storyJobs?.length) {
            message.info("Story fetched. Check them out!");
        }
    }, [storyJobs, storyJobsLoading]);

    useEffect(() => {
        if (!audioJobsLoading && audioJobs?.length) {
            message.info("Audio fetched. Check them out!");
        }
    }, [audioJobs, audioJobsLoading]);

    const updateLastSeen = async () => {
        if (
            user &&
            document?.visibilityState === "visible" &&
            navigator?.onLine
        ) {
            const {
                email,
                "https://hasura.io/jwt/claims/user_id": user_id,
                name,
            } = user;

            try {
                const {
                    data: { worksheet_worksheet_stats },
                } = await refetchWorksheetStats();
                let insertObject = _.cloneDeep(
                    worksheet_worksheet_stats?.[0]?.other || {},
                );

                if (insertObject?.active_users) {
                    const { active_users } = insertObject;

                    let isEditorActive = true;
                    let isUserPresent = false;

                    active_users.forEach(
                        (element: {
                            user_id: any;
                            is_editor: boolean;
                            last_seen: string | number | Date;
                        }) => {
                            if (user_id === element.user_id)
                                isUserPresent = true;
                            if (element.is_editor) {
                                const activeTime = new Date(
                                    element.last_seen,
                                ).getTime();
                                const currentTime = new Date().getTime();
                                const timeDifferenceSecs = Math.floor(
                                    (currentTime - activeTime) / 1000,
                                );

                                if (timeDifferenceSecs > 60) {
                                    isEditorActive = false;
                                }
                            }
                        },
                    );

                    let tempActiveUsers = _.cloneDeep(active_users);

                    if (!isUserPresent) {
                        tempActiveUsers.push({
                            user_id,
                            email,
                            name,
                            last_seen: new Date().toISOString(),
                            is_editor: false,
                        });
                    } else {
                        tempActiveUsers = tempActiveUsers.map(
                            (item: { user_id: any; last_seen: any }) => {
                                return {
                                    ...item,
                                    last_seen:
                                        user_id === item.user_id
                                            ? new Date().toISOString()
                                            : item.last_seen,
                                };
                            },
                        );
                    }

                    tempActiveUsers = tempActiveUsers.sort(
                        (
                            a: {
                                last_seen: string | number | Date;
                                is_editor: boolean;
                            },
                            b: {
                                last_seen: string | number | Date;
                                is_editor: boolean;
                            },
                        ) => {
                            if (a.is_editor && !b.is_editor) {
                                return -1; // a is an editor, b is not an editor (a comes first)
                            } else if (!a.is_editor && b.is_editor) {
                                return 1; // a is not an editor, b is an editor (b comes first)
                            } else {
                                const timeA = new Date(a.last_seen).getTime();
                                const timeB = new Date(b.last_seen).getTime();
                                return timeB - timeA; // Sort based on last_seen in descending order (latest on top)
                            }
                        },
                    );

                    if (!isEditorActive) {
                        tempActiveUsers = tempActiveUsers.map(
                            (item: { user_id: any }) => {
                                return {
                                    ...item,
                                    is_editor: user_id === item.user_id,
                                };
                            },
                        );
                    }

                    insertObject.active_users = tempActiveUsers;
                } else {
                    insertObject = {
                        ...insertObject,
                        active_users: [
                            {
                                user_id,
                                email,
                                name,
                                last_seen: new Date().toISOString(),
                                is_editor: true,
                            },
                        ],
                    };
                }

                let worksheetId = worksheet_id || getLastStringFromUrl();
                if (worksheetId) {
                    const response = await updateWorksheetUserStats({
                        other: insertObject,
                        worksheet_id: worksheetId,
                    });
                    setWorksheetStatsState(response);
                }
            } catch (e) {
                captureException(e);
                console.log(e);
            }
        }
    };

    const setActiveEditor = async () => {
        if (!navigator.onLine) {
            message.error("No internet!");
            return;
        }
        try {
            if (user) {
                const {
                    email,
                    "https://hasura.io/jwt/claims/user_id": user_id,
                    name,
                } = user;

                const {
                    data: { worksheet_worksheet_stats },
                } = await refetchWorksheetStats();
                let insertObject = _.cloneDeep(
                    worksheet_worksheet_stats?.[0]?.other || {},
                );

                if (insertObject?.active_users) {
                    const { active_users } = insertObject;

                    let isUserPresent = active_users.some(
                        (element: {
                            user_id: any;
                            is_editor: boolean;
                            last_seen: string | number | Date;
                        }) => {
                            return user_id === element.user_id;
                        },
                    );

                    let tempActiveUsers = _.cloneDeep(active_users);

                    if (!isUserPresent) {
                        tempActiveUsers.push({
                            user_id,
                            email,
                            name,
                            last_seen: new Date().toISOString(),
                            is_editor: true,
                        });
                    } else {
                        tempActiveUsers = tempActiveUsers.map(
                            (item: { user_id: any; last_seen: any }) => {
                                return {
                                    ...item,
                                    is_editor: user_id === item.user_id,
                                };
                            },
                        );
                    }

                    insertObject.active_users = tempActiveUsers;
                } else {
                    insertObject = {
                        ...insertObject,
                        active_users: [
                            {
                                user_id,
                                email,
                                name,
                                last_seen: new Date().toISOString(),
                                is_editor: true,
                            },
                        ],
                    };
                }

                const response = await updateWorksheetUserStats({
                    other: insertObject,
                    worksheet_id,
                });
                setWorksheetStatsState(response);
            }
        } catch (e) {
            captureException(e);
            console.log(e);
        }
    };

    if (window && history) {
        window.onpopstate = function () {
            setIsOpen(true);
        };
        history.pushState({}, "");
    }

    const handleClose = async () => setIsOpen(false);

    const validateBlocks = () => {
        const arr = blocks.map((blockObj: any) => {
            const tmpArr = [];

            tmpArr.push(checkBlockValid(blockObj, worksheet));

            if (blockObj.children && blockObj.children.length) {
                blockObj.children.map((child: any) => {
                    tmpArr.push(checkBlockValid(child, worksheet));
                });
            }
            return tmpArr;
        });
        return arr.flat();
    };

    const onFinish = async (values: any) => {
        await updateWorksheet({
            object: _.omit(values, ["tags"]),
            id: values.id,
        });
    };

    const setParsedBlocks = (blocksArray: any) => {
        if (blocksArray?.length) {
            const parsedBlocks = getV4ParsedBlocks(blocksArray);
            setBlocks(parsedBlocks || []);
            if (!currentBlock) {
                setCurrentBlock(0);
            }

            return parsedBlocks;
        }
        return [];
    };

    const saveBlocks = async ({
        currentBlocks,
        publish,
        levelReset,
        shouldPublishToSecondaryHasuraEndpoints = false,
    }: any) => {
        let retVal = true;
        let blockData = currentBlocks || blocks;

        const processBlocks = (levelBlocks) => {
            // Traverse function to recursively process each object

            const traverse = (obj) => {
                if (Array.isArray(obj)) {
                    obj.forEach(traverse);
                } else if (obj && typeof obj === "object") {
                    // Check if the object is a "chunk" with children
                    if (obj.type === "chunk" && Array.isArray(obj.children)) {
                        obj.children.forEach((child, index) => {
                            // Check if child is missing the `data` field and add it if missing
                            if (worksheet?.is_synced_block_worksheet) {
                                child.is_synced_block = true;
                            }
                            if (!child.data) {
                                if (child.master_block)
                                    child.data = {
                                        id: child.master_block,
                                        changes_list: child.data.changes_list,
                                    };
                                else child.data = { test: "test" };
                            }
                        });
                    }

                    // Recursively process other properties of the object if necessary
                    Object.values(obj).forEach(traverse);
                }
            };

            // Start traversal from the root object
            traverse(levelBlocks);

            // Return the updated levelBlocks after processing
            return levelBlocks;
        };
        let newBlockData = processBlocks(blockData);

        let resBlocks = await Promise.all(
            newBlockData?.map(async (level) => {
                if (!level?.id) {
                    const children = level?.children?.length
                        ? {
                              children: {
                                  data: level.children.map(
                                      (child: any, index: number) => {
                                          const gChildren = child?.children
                                              ?.length
                                              ? {
                                                    children: {
                                                        data: child.children.map(
                                                            (
                                                                grandchild: any,
                                                                gIndex: number,
                                                            ) => {
                                                                const isOnlyIdOrderType =
                                                                    Object.keys(
                                                                        grandchild,
                                                                    ).every(
                                                                        (key) =>
                                                                            [
                                                                                "id",
                                                                                "order",
                                                                                "type",
                                                                                "data",
                                                                            ].includes(
                                                                                key,
                                                                            ),
                                                                    ) &&
                                                                    grandchild.data &&
                                                                    Object.keys(
                                                                        grandchild.data,
                                                                    ).length ===
                                                                        1 &&
                                                                    grandchild
                                                                        .data
                                                                        .test !==
                                                                        undefined;

                                                                return {
                                                                    ..._.omit(
                                                                        grandchild,
                                                                        [
                                                                            "tmpId",
                                                                            "__typename",
                                                                            "tags",
                                                                            "children",
                                                                            "id",
                                                                            "is_modified",
                                                                            "master_block",
                                                                        ],
                                                                    ),
                                                                    data: isOnlyIdOrderType
                                                                        ? {
                                                                              id: grandchild.id,
                                                                          }
                                                                        : grandchild.is_modified
                                                                        ? {
                                                                              id: grandchild.master_block,
                                                                              changes_list:
                                                                                  grandchild
                                                                                      .data
                                                                                      .changes_list,
                                                                          }
                                                                        : {
                                                                              ...grandchild.data,
                                                                          },
                                                                    parent_id:
                                                                        child.id, // Link to the parent child block
                                                                    order:
                                                                        gIndex +
                                                                        1, // Set order based on the grandchild's index
                                                                    is_synced_block:
                                                                        worksheet?.is_synced_block_worksheet ||
                                                                        false, // Set sync flag
                                                                };
                                                            },
                                                        ),
                                                        on_conflict: {
                                                            constraint:
                                                                "block_pkey",
                                                            update_columns: [
                                                                "data",
                                                                "type",
                                                                "order",
                                                                "backend",
                                                            ],
                                                        },
                                                    },
                                                }
                                              : {};

                                          return {
                                              ..._.omit(child, [
                                                  "tmpId",
                                                  "__typename",
                                                  "tags",
                                                  "children",
                                                  "id",
                                              ]),
                                              ...gChildren, // Include the nested children structure if present
                                              order: index + 1, // Set the order based on the index
                                              parent_id: level.id, // Link to the parent level block
                                              is_synced_block:
                                                  worksheet?.is_synced_block_worksheet ||
                                                  false, // Set sync flag
                                          };
                                      },
                                  ),
                                  on_conflict: {
                                      constraint: "block_pkey",
                                      update_columns: [
                                          "data",
                                          "type",
                                          "order",
                                          "backend",
                                      ],
                                  },
                              },
                          }
                        : {};

                    const varData = {
                        ...children,
                        ..._.omit(level, [
                            "tmpId",
                            "worksheet_block_map_id",
                            "__typename",
                            "children",
                            "tags",
                        ]),
                    };

                    return varData;
                } else {
                    const savedLevelMap = worksheetBlockMap?.find(
                        (v) => v.block?.id === level?.id,
                    );
                    const savedLevel = savedLevelMap?.block;
                    if (
                        !_.isEqual(
                            _.omit(level, [
                                "id",
                                "tmpId",
                                "__typename",
                                "children",
                                "tags",
                                "worksheet_block_map_id",
                            ]),
                            _.omit(savedLevel, [
                                "id",
                                "tmpId",
                                "__typename",
                                "children",
                                "tags",
                                "worksheet_block_map_id",
                            ]),
                        )
                    ) {
                        await updateWorksheetBlock({
                            object: _.omit(level, [
                                "tmpId",
                                "worksheet_block_map_id",
                                "__typename",
                                "children",
                                "tags",
                            ]),
                            id: level.id,
                        })
                            .then((response) => {
                                console.log(
                                    "Worksheet block updated successfully:",
                                    response,
                                );
                            })
                            .catch((error) => {
                                console.error(
                                    "Error updating worksheet block:",
                                    error,
                                );
                            });
                    }
                    await Promise.all(
                        level?.children?.map(async (chunk, cIndex) => {
                            if (!chunk?.id) {
                                // Handling new chunk insertion
                                const children = chunk?.children?.length
                                    ? {
                                          children: {
                                              data: chunk.children.map(
                                                  (child, index) => {
                                                      const isOnlyIdOrderType =
                                                          Object.keys(
                                                              child,
                                                          ).every((key) =>
                                                              [
                                                                  "id",
                                                                  "order",
                                                                  "type",
                                                                  "data",
                                                              ].includes(key),
                                                          ) &&
                                                          child.data &&
                                                          Object.keys(
                                                              child.data,
                                                          ).length === 1 &&
                                                          child.data.test !==
                                                              undefined;

                                                      return {
                                                          ..._.omit(child, [
                                                              "tmpId",
                                                              "worksheet_block_map_id",
                                                              "__typename",
                                                              "children",
                                                              "tags",
                                                              "id",
                                                              "is_modified",
                                                              "master_block",
                                                          ]),
                                                          data: isOnlyIdOrderType
                                                              ? { id: child.id }
                                                              : child.is_modified
                                                              ? {
                                                                    id: child.master_block,
                                                                    changes_list:
                                                                        child
                                                                            .data
                                                                            .changes_list,
                                                                }
                                                              : {
                                                                    ...child.data,
                                                                },
                                                          parent_id: chunk.id,
                                                          order: index + 1,
                                                          is_synced_block:
                                                              worksheet?.is_synced_block_worksheet ||
                                                              false,
                                                      };
                                                  },
                                              ),
                                              on_conflict: {
                                                  constraint: "block_pkey",
                                                  update_columns: [
                                                      "data",
                                                      "type",
                                                      "order",
                                                      "backend",
                                                  ],
                                              },
                                          },
                                      }
                                    : {};

                                const varData = {
                                    ...children,
                                    ..._.omit(chunk, [
                                        "tmpId",
                                        "worksheet_block_map_id",
                                        "__typename",
                                        "children",
                                        "tags",
                                        "id",
                                    ]),
                                    order: cIndex + 1,
                                    parent_id: level.id,
                                };

                                const data = await insertWorksheetBlock(
                                    varData,
                                );
                                return data?.id;
                            } else {
                                // Handling existing chunk update
                                const savedChunk = savedLevel?.children?.find(
                                    (v) => v?.id === chunk?.id,
                                );
                                const updatedChunkData = {
                                    ..._.omit({ ...chunk, order: cIndex + 1 }, [
                                        "id",
                                        "tmpId",
                                        "__typename",
                                        "children",
                                        "tags",
                                    ]),
                                };

                                if (
                                    !_.isEqual(
                                        updatedChunkData,
                                        _.omit(savedChunk, [
                                            "id",
                                            "tmpId",
                                            "__typename",
                                            "children",
                                            "tags",
                                        ]),
                                    )
                                ) {
                                    await updateWorksheetBlock({
                                        object: updatedChunkData,
                                        id: chunk.id,
                                    })
                                        .then((response) => {
                                            console.log(
                                                "Chunk updated successfully:",
                                                response,
                                            );
                                        })
                                        .catch((error) => {
                                            console.error(
                                                "Error updating chunk:",
                                                error,
                                            );
                                        });
                                }

                                // Process children of the chunk
                                await Promise.all(
                                    chunk?.children?.map(
                                        async (block, bIndex) => {
                                            const isOnlyIdOrderType =
                                                Object.keys(block).every(
                                                    (key) =>
                                                        [
                                                            "id",
                                                            "order",
                                                            "type",
                                                            "data",
                                                        ].includes(key),
                                                ) &&
                                                block.data &&
                                                block.data.test !== undefined;

                                            const savedBlock =
                                                savedChunk?.children?.find(
                                                    (v) => v?.id === block?.id,
                                                );

                                            const updatedBlockData = {
                                                ..._.omit(block, [
                                                    "tmpId",
                                                    "worksheet_block_map_id",
                                                    "__typename",
                                                    "children",
                                                    "tags",
                                                    "id",
                                                    "is_modified",
                                                    "master_block",
                                                ]),
                                                data: isOnlyIdOrderType
                                                    ? {
                                                          id: block.id,
                                                          ...(block.data
                                                              .changes_list !==
                                                              undefined && {
                                                              changes_list:
                                                                  block.data
                                                                      .changes_list,
                                                          }),
                                                      }
                                                    : block.is_modified
                                                    ? {
                                                          id: block.master_block,
                                                          ...(block.data
                                                              .changes_list !==
                                                              undefined && {
                                                              changes_list:
                                                                  block.data
                                                                      .changes_list,
                                                          }),
                                                      }
                                                    : {
                                                          ...block.data,
                                                      },

                                                parent_id: chunk.id,
                                                order: bIndex + 1,
                                                is_synced_block:
                                                    worksheet?.is_synced_block_worksheet ||
                                                    false,
                                                backend:
                                                    isOnlyIdOrderType ||
                                                    block.is_modified
                                                        ? null
                                                        : block.backend, // Set backend to null if the first two conditions are true
                                            };

                                            if (
                                                !block.id ||
                                                isOnlyIdOrderType ||
                                                block.is_modified === false
                                            ) {
                                                const data =
                                                    await insertWorksheetBlock(
                                                        updatedBlockData,
                                                    );
                                                return data?.id;
                                            } else {
                                                // Ensure properties like 'name' are not unintentionally modified

                                                const savedBlockData = _.omit(
                                                    savedBlock,
                                                    [
                                                        "tmpId",
                                                        "worksheet_block_map_id",
                                                        "__typename",
                                                        "children",
                                                        "tags",
                                                    ],
                                                );

                                                if (
                                                    !_.isEqual(
                                                        updatedBlockData?.backend,
                                                        savedBlockData?.backend,
                                                    ) ||
                                                    !_.isEqual(
                                                        updatedBlockData?.data,
                                                        savedBlockData?.data,
                                                    )
                                                ) {
                                                    await updateWorksheetBlock({
                                                        object: updatedBlockData,
                                                        id: block.id,
                                                    })
                                                        .then((response) => {
                                                            console.log(
                                                                "Block updated successfully:",
                                                                response,
                                                            );
                                                        })
                                                        .catch((error) => {
                                                            console.error(
                                                                "Error updating block:",
                                                                error,
                                                            );
                                                        });
                                                }
                                            }
                                            return block?.id;
                                        },
                                    ),
                                );
                                return chunk?.id;
                            }
                        }),
                    );

                    return {
                        levelId: level?.id,
                        worksheet_block_map_id: savedLevelMap?.id,
                    };
                }
            }),
        );

        // const processChunks = (obj: any) => {
        //     const traverse = (obj: any) => {
        //         // If the object has 'children', process it accordingly
        //         if (obj && typeof obj === 'object') {
        //             console.log("Processing object:", obj);

        //             // Check if the object is of type 'chunk' with 'children' array
        //             if (obj.type === 'chunk' && Array.isArray(obj.children)) {
        //                 console.log("Found a 'chunk' with children:", obj.children);

        //                 // Process each child inside the children array
        //                 obj.children = obj.children.map((child: any) => {
        //                     // Check if the child object contains 'id', 'type', and 'order'
        //                     if ('id' in child && 'type' in child && 'order' in child) {
        //                         return {
        //                             ...child,  // Keep all the properties of the child
        //                             _typename: 'worksheet_block',  // Add _typename property to the child
        //                         };
        //                     } else {
        //                         // Return the child as-is if it doesn't meet the criteria
        //                         return child;
        //                     }
        //                 });
        //             }

        //             // Recursively traverse the children of the current object (if they exist)
        //             if (Array.isArray(obj.children)) {
        //                 obj.children = obj.children.map(traverse);
        //             }
        //         }

        //         return obj; // Return the (possibly modified) object
        //     };

        //     return traverse(obj); // Start processing the input object
        // };

        // // Example usage for processing blockData:
        // const processedBlockData = processChunks(blockData);
        //console.log(processedBlockData)
        const objects: any = getV4WorksheetBlockMapObject({
            blockData,
            worksheetData: { worksheet_id },
        });

        const updateObjects: any = getWorksheetBlockIdMap({
            blockData: resBlocks,
            worksheetData: { worksheet_id },
        });

        const existingWorksheetBlockMapIds = objects
            .map(({ id }: any) => id)
            .filter((n: any) => n);
        const allWorksheetBlockMapIds = worksheetBlockMap.map(
            ({ id }: any) => id,
        );
        const missingWorksheetBlockMapIds = allWorksheetBlockMapIds.filter(
            (x: any) => !existingWorksheetBlockMapIds.includes(x),
        );

        const allChildrenBlockIds = worksheetBlockMap
            .flatMap(({ block: { children, id } }: any) => [
                id,
                ...children.flatMap(({ id, children: gChildren }: any) => {
                    const gChildrenIds = (gChildren || []).map(
                        ({ id }: any) => id,
                    );
                    return [id, ...gChildrenIds];
                }),
            ])
            .filter((n: any) => n);

        const existingChildrenBlockIds = blockData
            ?.flatMap(({ children = [], id }: any) => [
                id,
                ...children?.flatMap(
                    ({ id, children: gChildren = [] }: any) => [
                        id,
                        ...gChildren?.map(({ id }: any) => id),
                    ],
                ),
            ])
            .filter((n: any) => n);

        const missingChildrenBlockIds = allChildrenBlockIds.filter(
            (x: any) => !existingChildrenBlockIds.includes(x),
        );

        if (missingChildrenBlockIds.length) {
            console.log("deletion block", missingChildrenBlockIds);
            await deleteBlocks({ ids: missingChildrenBlockIds });
        }

        if (missingWorksheetBlockMapIds.length)
            console.log("deletion block here", missingWorksheetBlockMapIds);
        await deleteWorksheetBlockMap({
            ids: missingWorksheetBlockMapIds,
        });

        await insertWorksheetBlockMap({
            objects: updateObjects,
        });

        const {
            data: { worksheetBlockMap: newWorksheetBlockMap },
        } = await refetchWorksheetBlockMap();

        const processLevelBlocks = (levelBlocks, syncBlocks) => {
            // Traverse function to recursively process each object
            const traverse = (obj) => {
                if (Array.isArray(obj)) {
                    return obj.map(traverse); // Return a new array for processing children
                } else if (obj && typeof obj === "object") {
                    // Check if the object is a "chunk" with children
                    if (obj.type === "chunk" && Array.isArray(obj.children)) {
                        // Process children and create a new children array
                        const updatedChildren = obj.children.map(
                            (child, index) => {
                                if (child?.data && child.data.id) {
                                    const childId = child.data.id;

                                    // Find matching sync block by ID
                                    const matchingSyncBlock = syncBlocks.find(
                                        (syncBlock) => syncBlock.id === childId,
                                    );

                                    if (matchingSyncBlock) {
                                        // Apply changes function
                                        const applyChanges = (
                                            block,
                                            changesList,
                                        ) => {
                                            if (
                                                !changesList ||
                                                typeof changesList !== "object"
                                            ) {
                                                return block; // Return the block unchanged if changesList is invalid
                                            }

                                            // Create a deep copy of the block to avoid mutating the original
                                            const mutableBlock =
                                                structuredClone(block);

                                            Object.entries(changesList).forEach(
                                                ([key, value]) => {
                                                    const keys = key.split(".");
                                                    let target = mutableBlock;

                                                    // Traverse to the target property, creating nested objects if necessary
                                                    keys.slice(0, -1).forEach(
                                                        (nestedKey) => {
                                                            if (
                                                                typeof target[
                                                                    nestedKey
                                                                ] !==
                                                                    "object" ||
                                                                target[
                                                                    nestedKey
                                                                ] === null
                                                            ) {
                                                                target[
                                                                    nestedKey
                                                                ] = {}; // Initialize as object if undefined or null
                                                            }
                                                            target =
                                                                target[
                                                                    nestedKey
                                                                ]; // Move to the next level
                                                        },
                                                    );

                                                    // Apply the final value at the last key
                                                    const finalKey =
                                                        keys[keys.length - 1];
                                                    target[finalKey] = value;
                                                },
                                            );

                                            return mutableBlock;
                                        };

                                        const updatedChild = applyChanges(
                                            matchingSyncBlock,
                                            child?.data?.changes_list,
                                        );

                                        // Create a new child object with the updated data and other properties
                                        return {
                                            ...child,
                                            data: {
                                                ...updatedChild.data,
                                                changes_list:
                                                    child.data.changes_list,
                                            },
                                            backend: {
                                                ...updatedChild.backend,
                                            },
                                            is_modified: true,
                                            master_block: updatedChild.id,
                                        };
                                    } else {
                                        console.log(
                                            `No matching sync block found for child ID: ${childId}`,
                                        );
                                    }
                                }
                                // Return the child unmodified if no matching block is found
                                return child;
                            },
                        );

                        // Return a new object with updated children
                        return {
                            ...obj,
                            children: updatedChildren,
                        };
                    }

                    // Recursively process other properties of the object if necessary
                    return Object.fromEntries(
                        Object.entries(obj).map(([key, value]) => [
                            key,
                            traverse(value),
                        ]),
                    );
                }
                // Return primitive values as is
                return obj;
            };

            // Start traversal from the root object

            const result = traverse(levelBlocks);

            // Return the updated levelBlocks after processing
            return result;
        };

        let updatedWorksheetBlockMap = processLevelBlocks(
            newWorksheetBlockMap,
            syncBlocks,
        );

        blockData = setParsedBlocks(updatedWorksheetBlockMap);

        // START: handle versioning
        const {
            data: { worksheet_worksheet_stats },
        } = await refetchWorksheetStats();
        let insertObject = _.cloneDeep(
            worksheet_worksheet_stats?.[0]?.other || {},
        );

        const { save_count } = insertObject;

        const {
            email,
            "https://hasura.io/jwt/claims/user_id": user_id,
            name,
        } = user || {};

        let currentSaved = {
            time: new Date(),
            user: {
                email,
                user_id,
                name,
            },
        };
        insertObject.save_count = (save_count || 0) + 1;
        insertObject.current_save = currentSaved;
        insertObject.block_count = blockData?.length;
        if (publish) {
            try {
                // const res =
                await axios
                    .post(
                        `${process.env.REACT_APP_HOMEWORK_SERVER_API_ENDPOINT}/v3/personalizedLearning/publishWorksheetForAllRegion`,
                        {
                            data: {
                                worksheet_id: Number(worksheet_id),
                                level_reset: levelReset || [],
                                shouldPublishToSecondaryHasuraEndpoints,
                                userData: currentSaved,
                            },
                        },
                        { headers: { "Content-Type": "application/json" } },
                    )
                    .then(function (response) {
                        return response?.data;
                    });

                // console.log("RESPONSE FROM API", res);
                insertObject.publish_count = (save_count || 0) + 1;
                insertObject.current_publish = currentSaved;

                refetchPublishedBlocks();
                // await axios.post(
                //     `${process.env.REACT_APP_HOMEWORK_SERVER_API_ENDPOINT}/v3/personalizedLearning/deleteWorksheetMetadataCache`,
                //     { data: { id: worksheet_id } },
                // );
            } catch (e) {
                captureException(e);
                retVal = false;
                console.log("Publish Failed", e);
            }
        }

        const response = await updateWorksheetUserStats({
            other: insertObject,
            worksheet_id,
        });

        setCurrentSavedCount(response?.other?.save_count);
        setWorksheetStatsState(response);
        // END: handle versioning

        // publish to other endpoints
        // if (shouldPublishToSecondaryHasuraEndpoints)
        //     await publishToSecondaryHasuraEndpoints(
        //         worksheet,
        //         newWorksheetBlockMap,
        //         levelReset,
        //     );

        return retVal;
    };

    const onSave = async ({
        showMessage = true,
        publish = false,
        generate = true,
        levelReset,
        shouldPublishToSecondaryHasuraEndpoints,
    }: any) => {
        const removeSelectedFlag = (obj) =>
            Array.isArray(obj)
                ? obj.map(removeSelectedFlag)
                : obj && typeof obj === "object"
                ? Object.fromEntries(
                      Object.entries(obj)
                          .filter(([key]) => key !== "selected_flag") // Remove the 'selected_flag' key
                          .map(([key, value]) => [
                              key,
                              removeSelectedFlag(value),
                          ]), // Recursively remove from nested values
                  )
                : obj; // Return primitive values as is

        const processChunks = (obj: any) => {
            if (Array.isArray(obj)) {
                // Recursively process each item in the array and ensure it's a new array
                return obj.map(processChunks);
            } else if (obj && typeof obj === "object") {
                // Check if the object is a "chunk"
                if (obj.type === "chunk" && Array.isArray(obj.children)) {
                    // Create a new array for modified children
                    const newChildren = obj.children.map((child, index) => {
                        if (
                            child &&
                            typeof child === "object" &&
                            "parent_id" in child
                        ) {
                            // Retain changeList if it exists
                            const retainedChangeList =
                                child?.data?.changes_list || null;

                            // Return a new object for each child to avoid direct mutation
                            return {
                                id: child.id,
                                order: index + 1,
                                type: child.type,
                                data:
                                    retainedChangeList !== null
                                        ? {
                                              changes_list: retainedChangeList,
                                          }
                                        : undefined, // Only include `data` if `retainedChangeList` is not null
                            };
                        }
                        // Return a shallow copy if it's not the type we want to modify
                        return { ...child };
                    });

                    // Return a new chunk object with updated children (create a new object to avoid direct mutation)
                    return {
                        ...obj,
                        children: newChildren,
                    };
                }

                // Process nested properties recursively and return a new object each time
                return Object.fromEntries(
                    Object.entries(obj).map(([key, value]) => [
                        key,
                        processChunks(value),
                    ]),
                );
            } else {
                // Return primitive values as they are
                return obj;
            }
        };

        if (!navigator.onLine) {
            message.error("No internet!");
            return false;
        }
        let retVal = true;
        if (!isSaving && blocks.length) {
            toggleSaving(true);
            let newCurrentBlocksVisited = blocks.map(removeSelectedFlag);

            try {
                if (showMessage) toggleShouldBlockUI(true);
                // NEEDS UPDATE
                const newCurrentBlocks = await addGenerateLogicForVarAudio(
                    newCurrentBlocksVisited,
                    worksheet?.type,
                );
                if (publish || generate) {
                    console.log("blocks passed", blocks);
                    // NEEDS UPDATE
                    const { job_id, error } = await generateAudios(
                        newCurrentBlocks,
                        worksheet?.type,
                    );
                    if (job_id) {
                        setNewAudioJob({ job_id });
                    }
                    if (error) {
                        message.error(
                            `Failed to generate audio for worksheet!`,
                        );
                        console.log("Error audio:", error);
                        // return false;
                    }
                }

                let finalCurrentBlocks = processChunks(newCurrentBlocks);

                let [res] = await Promise.all([
                    saveBlocks({
                        currentBlocks: finalCurrentBlocks,
                        publish,
                        worksheet_type: worksheet.type,
                        levelReset,
                        shouldPublishToSecondaryHasuraEndpoints,
                    }),
                    delay(1000),
                ]);

                if (!res) retVal = false;
                setLastSavedAt(new Date());
                if (showMessage) {
                    message.success(`Successfully updated worksheet!`);
                }
            } catch (e) {
                captureException(e);
                console.log("Error on save:", e);
                if (showMessage)
                    message.error(
                        `Something went wrong! try again in a couple of seconds`,
                    );
                retVal = false;
            }

            toggleHasChanged(false);
            toggleShouldBlockUI(false);
            toggleSaving(false);
        } else if (isSaving) {
            toggleHasChanged(false);
            toggleSaving(false);
            toggleShouldBlockUI(false);
        }
        return retVal;
    };

    const setNewJob = async ({ path, job_id, name }) => {
        if (!navigator.onLine) {
            message.error("No internet!");
            return;
        }
        try {
            const {
                data: { worksheet_worksheet_stats },
            } = await refetchWorksheetStats();
            let insertObject = _.cloneDeep(
                worksheet_worksheet_stats?.[0]?.other || {},
            );
            if (insertObject?.story_jobs) {
                const { story_jobs } = insertObject;

                let tempJobs = _.cloneDeep(story_jobs);

                tempJobs.unshift({
                    name,
                    path,
                    job_id,
                    is_sync: false,
                });

                insertObject.story_jobs = tempJobs;
            } else {
                insertObject = {
                    ...insertObject,
                    story_jobs: [{ name, path, job_id, is_sync: false }],
                };
            }
            const response = await updateWorksheetUserStats({
                other: insertObject,
                worksheet_id,
            });
            setWorksheetStatsState(response);
        } catch (e) {
            captureException(e);
            console.log(e);
        }
    };
    const updateStoriesJob = async ({ job_id, is_delete }: any) => {
        if (!navigator.onLine) {
            message.error("No internet!");
            return false;
        }
        try {
            const {
                data: { worksheet_worksheet_stats },
            } = await refetchWorksheetStats();
            let insertObject = _.cloneDeep(
                worksheet_worksheet_stats?.[0]?.other || {},
            );
            if (insertObject?.story_jobs) {
                const { story_jobs } = insertObject;

                let tempJobs = _.cloneDeep(story_jobs);

                tempJobs = is_delete
                    ? tempJobs.filter((v) => v.job_id != job_id)
                    : tempJobs.map((v) => {
                          if (v.job_id == job_id) {
                              v.is_sync = true;
                          }
                          return v;
                      });
                insertObject.story_jobs = tempJobs;
            }
            if (!is_delete) setSaveVal(true);
            const response = await updateWorksheetUserStats({
                other: insertObject,
                worksheet_id,
            });
            setWorksheetStatsState(response);
            return true;
        } catch (e) {
            captureException(e);
            console.log(e);
            return false;
        }
    };

    const setNewAudioJob = async ({ job_id }) => {
        if (!navigator.onLine) {
            message.error("No internet!");
            return;
        }
        try {
            const {
                data: { worksheet_worksheet_stats },
            } = await refetchWorksheetStats();
            let insertObject = _.cloneDeep(
                worksheet_worksheet_stats?.[0]?.other || {},
            );
            if (insertObject?.audio_jobs) {
                const { audio_jobs } = insertObject;

                let tempJobs = _.cloneDeep(audio_jobs);

                tempJobs.unshift({
                    job_id,
                    is_sync: false,
                });

                insertObject.audio_jobs = tempJobs;
            } else {
                insertObject = {
                    ...insertObject,
                    audio_jobs: [
                        {
                            job_id,
                            is_sync: false,
                        },
                    ],
                };
            }
            const response = await updateWorksheetUserStats({
                other: insertObject,
                worksheet_id,
            });
            setWorksheetStatsState(response);
        } catch (e) {
            captureException(e);
            console.log(e);
        }
    };
    const updateAudiosJob = async ({ job_id, is_delete }: any) => {
        if (!navigator.onLine) {
            message.error("No internet!");
            return false;
        }
        try {
            const {
                data: { worksheet_worksheet_stats },
            } = await refetchWorksheetStats();
            let insertObject = _.cloneDeep(
                worksheet_worksheet_stats?.[0]?.other || {},
            );
            if (insertObject?.audio_jobs) {
                const { audio_jobs } = insertObject;

                let tempJobs = _.cloneDeep(audio_jobs);

                tempJobs = is_delete
                    ? tempJobs.filter((v) => v.job_id != job_id)
                    : tempJobs.map((v) => {
                          if (v.job_id == job_id) {
                              v.is_sync = true;
                          }
                          return v;
                      });
                insertObject.audio_jobs = tempJobs;
            }
            const response = await updateWorksheetUserStats({
                other: insertObject,
                worksheet_id,
            });
            setWorksheetStatsState(response);
            if (!is_delete) setSaveVal(true);
            return true;
        } catch (e) {
            captureException(e);
            console.log(e);
            return false;
        }
    };

    // console.log(props);
    const version =
        versionModelMap &&
        versionModelMap[0]?.versions_aggregate?.aggregate?.max?.index + 1; // versionModelMap

    const createViewProps = {
        onFinish,
        onFinishFailed: () => {},
        redirect_to,
        isSaving,
        blocks,
        setBlocks: (blocksArray: any) => {
            setBlocks(blocksArray);
            toggleHasChanged(true);
        },
        onSave,
        currentBlock,
        setCurrentBlock: (blockIndex: number) => {
            setCurrentBlock(blockIndex);
            setCurrentSubBlock(null);
            setCurrentChunkBlock(null);
        },
        worksheet_id,
        currentSubBlock,
        setCurrentSubBlock,
        currentChunkBlock,
        setCurrentChunkBlock,
        lastSavedAt,
        currentDateTime: null,
        setSaveCounter: ({ currentBlocks }: any) => {
            onSave({ showMessage: true, currentBlocks });
        },
        shouldBlockUI,
        toggleShouldBlockUI,
        validateBlocks,
        version,
        hasChanged,
        toggleHasChanged,
        canPublish:
            worksheetStatsState?.other?.save_count !==
            worksheetStatsState?.other?.publish_count,
        onInputVariableUpdate: () => {},
        checkInputVarExist: () => {},
        activeUsers: worksheetStatsState?.other?.active_users,
        storyJobs,
        worksheetStoryJobs: worksheetStatsState?.other?.story_jobs || [],
        setActiveEditor,
        setNewJob,
        updateStoriesJob,
        audioJobs,
        worksheetAudioJobs: worksheetStatsState?.other?.audio_jobs || [],
        updateAudiosJob,
        setNewAudioJob,
        currentSavedCount,
        publishedBlocksMap,
        worksheetWorksheetStatsUS,
    };

    if (loadingWorksheetMap) return <></>;
    return (
        <>
            <Modal
                open={isOpen}
                title="Are you sure you want to go back?"
                onOk={() => {
                    handleClose();
                    router.push(redirect_to);
                }}
                onCancel={handleClose}
            >
                <Typography.Text>
                    You'll lose any unsaved changes
                </Typography.Text>
            </Modal>
            <CreateView {...props} {...createViewProps} />
        </>
    );
};

export default compose(
    withHookForWorksheet,
    withUpdateWorksheet,
    withWorksheetBlocks,
    withWorksheetPublishedBlocks,
    withInsertWorksheetBlockMap,
    withDeleteWorksheetBlockMap,
    withDeleteBlocks,
    withVersionModelMap,
    withUpdateWorksheetUserStats,
    withWorksheetStats,
    withHookForWorksheetStories,
    withHookForWorksheetAudios,
    withUpdateWorksheetBlock,
    withInsertWorksheetBlock,
)(WorksheetEdit);
