import { Typography, Modal, List } from "antd";
import React from "react";

const { Text, Title } = Typography;

interface ChangesModalProps {
    changesList: Record<string, any>;
    isReadOnlyMode: boolean;
    isModalOpen: boolean;
    setIsModalOpen: (open: boolean) => void;
}

const ChangesModal: React.FC<ChangesModalProps> = ({
    changesList = {},
    isReadOnlyMode,
    isModalOpen,
    setIsModalOpen,
}) => {
    const changesData = Object.entries(changesList);

    const hideModal = () => {
        setIsModalOpen(false);
    };

    return (
        <Modal
            title="Changes List"
            style={{ top: 20 }}
            open={isModalOpen}
            onOk={hideModal}
            onCancel={hideModal}
            footer={null}
            closable={true}
            maskClosable={true}
            width={600}
            destroyOnClose
        >
            {changesData.length === 0 ? (
                <Title type="danger" level={5}>
                    No changes available.
                </Title>
            ) : (
                <List
                    size="large"
                    bordered
                    dataSource={changesData}
                    renderItem={(
                        [key, value]: [string, any],
                        index: number,
                    ) => (
                        <List.Item key={index}>
                            <List.Item.Meta title={<Text strong>{key}</Text>} />
                        </List.Item>
                    )}
                />
            )}
        </Modal>
    );
};

export default ChangesModal;
