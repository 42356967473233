import { gql } from "@apollo/client";

export default gql`
    mutation UpdateBlocksParentId($ids: [Int!]!) {
        update_worksheet_block(
            where: { id: { _in: $ids } }
            _set: { parent_id: null }
        ) {
            affected_rows
        }
    }
`;
