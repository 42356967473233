import { gql } from "@apollo/client";

export default gql`
    query MyQuery {
        blocks: worksheet_block(
            where: {
                is_synced_block: { _eq: true }
                _and: [{ backend: { _is_null: false } }]
            }
        ) {
            id
            backend
            data
            type
            __typename
            parent_id
        }
    }
`;
