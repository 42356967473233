import { gql } from "@apollo/client";
export default gql`
    query worksheet($id: Int!) {
        worksheet: worksheet_worksheet_by_pk(id: $id) {
            id
            status
            banner
            created_at
            title
            description
            is_synced_block_worksheet
            type
            class
            subject
            slug
            updated_at
            user {
                id
                name
                email
            }
            tags {
                id
                tag_id
                tag {
                    id
                    name
                    value
                }
            }
            other
            stats {
                id
                set_id
                gcp_spreadsheet_id
                collection_id
            }
            metadata_tags
        }
    }
`;
