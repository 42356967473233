import { gql } from "@apollo/client";
export default gql`
    mutation InsertSingleWorksheetBlockMap(
        $worksheet_id: Int!
        $block_id: Int!
        $order: Int!
        $parent_id: Int!
    ) {
        insert_worksheet_worksheet_block_map_one(
            object: {
                worksheet_id: $worksheet_id
                block_id: $block_id
                order: $order
                parent_id: $parent_id
            }
            on_conflict: {
                constraint: worksheet_block_map_pkey
                update_columns: [order]
            }
        ) {
            id
            order
            worksheet_id
            parent_id
        }
    }
`;
