import { gql } from "@apollo/client";
export default gql`
    query GetWorksheetIdByNestedBlockId($blockId: Int!) {
        worksheet_worksheet_block_map(
            where: {
                block: { children: { children: { id: { _eq: $blockId } } } }
            }
        ) {
            worksheet_id
        }
    }
`;
