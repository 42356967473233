import React, { useEffect, useMemo } from "react";
import Text from "./Text";
import InputComponent from "./Input";
import DropdownComponent from "./Dropdown";
import ImageComponent from "./Image";
import DropAreaComponent from "./DropArea";
import { generateBorderConfig } from "./helpers";
import CellWrapperComponent from "./CellWrapperComponent";
import emitter from "src/helpers/emitter";
import {
    MAIN_TABLE_CELL_SELECTED,
    REMOVE_ALL_HIGHLIGHTS,
    RESET_SELECTION,
    TOGGLE_HIGHLIGHT,
    TOGGLE_HIGHLIGHT_PATH,
    TOGGLE_SELECTION,
} from "src/helpers/events/table/constants";
import PuzzleTagManager from "../Puzzle/PuzzleTagManager";

const CellComponent = ({
    cell,
    rowIndex,
    cellIndex,
    minMaxWidth,
    minHeightCustomization,
    borderCustomizations,
    paddingCustomization,
    isDrawing,
    customStyles,
    puzzleEnabled,
    tagActive,
    isPuzzlePiece,
    isPuzzleEnabled,
    mainTableSelectionActive,
}: any) => {
    const [isHovering, setIsHovering] = React.useState(false);
    const [isPeeked, setIsPeeked] = React.useState(false);
    const [isSelected, setIsSelected] = React.useState(false);

    const handleChangeHover = (hovering: boolean) => {
        if (isHovering !== hovering) {
            emitter.emit(TOGGLE_HIGHLIGHT, {
                coordinates: {
                    rowIndex,
                    columnIndex: cellIndex,
                },
                isHighlighted: hovering,
            });
            setIsHovering(hovering);
        }
    };

    const handleToggleSelection = async (selected: boolean) => {
        if (mainTableSelectionActive) {
            if (selected) {
                emitter.emit(MAIN_TABLE_CELL_SELECTED, {
                    coordinates: {
                        rowIndex,
                        columnIndex: cellIndex,
                    },
                    isAdded: selected,
                });
            } else {
                emitter.emit(MAIN_TABLE_CELL_SELECTED, {
                    coordinates: {
                        rowIndex,
                        columnIndex: cellIndex,
                    },
                    isAdded: selected,
                });
            }
            setIsSelected(selected);
        } else if (tagActive) {
            if (selected) {
                PuzzleTagManager.addSelectedCell(cell, rowIndex, cellIndex);
                setIsPeeked(true);
            } else {
                PuzzleTagManager.removeSelectedCell(cell, rowIndex, cellIndex);
                setIsPeeked(false);
            }
        } else {
            emitter.emit(TOGGLE_SELECTION, {
                coordinates: {
                    rowIndex,
                    columnIndex: cellIndex,
                },
                isAdded: selected,
            });
            setIsPeeked(selected);
        }
    };

    useEffect(() => {
        const listener = (data: any) => {
            setIsSelected(false);
        };
        emitter.on(RESET_SELECTION, listener);
        return () => {
            emitter.off(RESET_SELECTION, listener);
        };
    }, []);

    useEffect(() => {
        if (!tagActive) {
            setIsPeeked(false);
        }
    }, [tagActive]);

    useEffect(() => {
        const handleOutsideHighlight = (data: any) => {
            const coordinates = data?.coordinates;
            if (
                coordinates?.rowIndex !== rowIndex ||
                coordinates?.columnIndex !== cellIndex
            ) {
                return;
            }
            if (data?.isHighlighted == isHovering) {
                return;
            }
            if (data?.isHighlighted) {
                setIsHovering(true);
            } else {
                setIsHovering(false);
            }
        };
        emitter.on(TOGGLE_HIGHLIGHT, handleOutsideHighlight);
        return () => {
            emitter.off(TOGGLE_HIGHLIGHT, handleOutsideHighlight);
        };
    }, [rowIndex, cellIndex, isHovering]);

    useEffect(() => {
        const handlePeek = (data: any) => {
            const cells = data?.cells;
            const currentCellExists = cells?.find(
                (cell: any) => cell[0] === rowIndex && cell[1] === cellIndex,
            );
            if (!currentCellExists) {
                return;
            }
            if (data?.isHighlighted) {
                setIsPeeked(true);
            } else {
                setIsPeeked(false);
            }
        };
        emitter.on(TOGGLE_HIGHLIGHT_PATH, handlePeek);
        return () => {
            emitter.off(TOGGLE_HIGHLIGHT_PATH, handlePeek);
        };
    }, [rowIndex, cellIndex]);

    useEffect(() => {
        const handleRemovePeeked = ({ resolve }: any) => {
            setIsPeeked(false);
            setIsHovering(false);
            resolve("");
        };
        emitter.on(REMOVE_ALL_HIGHLIGHTS, handleRemovePeeked);

        return () => {
            emitter.off(REMOVE_ALL_HIGHLIGHTS, handleRemovePeeked);
        };
    }, []);

    const borderStyle = useMemo(() => {
        if (isDrawing) return {};

        return cell.border?.enabled
            ? generateBorderConfig(cell.border)
            : borderCustomizations
            ? generateBorderConfig(borderCustomizations)
            : {
                  border: "1px solid #828282",
              };
    }, [cell.border, borderCustomizations, isDrawing]);

    const currentMinMaxWidthStyle = useMemo(() => {
        if (isPuzzlePiece) {
            let styleObj: any = {};
            if (minMaxWidth?.enabled) {
                if (minMaxWidth?.minWidth) {
                    styleObj["minWidth"] = minMaxWidth?.minWidth;
                }
                if (minMaxWidth?.maxWidth) {
                    styleObj["maxWidth"] = minMaxWidth?.maxWidth;
                }
            }
            return styleObj;
        }
        if (minMaxWidth) {
            if (minMaxWidth[cellIndex]?.enabled) {
                return {
                    minWidth: minMaxWidth[cellIndex]?.minWidth,
                    maxWidth: minMaxWidth[cellIndex]?.maxWidth,
                };
            }
        }
        return {};
    }, [minMaxWidth, cellIndex]);

    const currentMinMaxHeightStyle = useMemo(() => {
        if (isPuzzlePiece) {
            let styleObj: any = {};
            if (minHeightCustomization?.enabled) {
                if (minHeightCustomization?.minHeight) {
                    styleObj["minHeight"] = minHeightCustomization?.minHeight;
                }
                if (minHeightCustomization?.maxHeight) {
                    styleObj["maxHeight"] = minHeightCustomization?.maxHeight;
                }
            }
            return styleObj;
        }
        if (minHeightCustomization) {
            if (minHeightCustomization[rowIndex]?.enabled) {
                let styleObj = {};
                if (minHeightCustomization[rowIndex]?.minHeight) {
                    styleObj["minHeight"] =
                        minHeightCustomization[rowIndex]?.minHeight;
                }
                if (minHeightCustomization[rowIndex]?.maxHeight) {
                    styleObj["maxHeight"] =
                        minHeightCustomization[rowIndex]?.maxHeight;
                }
                return styleObj;
            }
        }
        return {};
    }, [minHeightCustomization, rowIndex]);

    const { cellAlignContentStyle, cellWrapperAlignContentStyle } =
        useMemo(() => {
            if (cell.align && cell.align !== "LEFT") {
                return {
                    cellAlignContentStyle: {
                        justifyContent: cell.align.toLowerCase(),
                    },
                    cellWrapperAlignContentStyle: {
                        textAlign: cell.align.toLowerCase(),
                    },
                };
            }
            return {
                cellAlignContentStyle: {},
                cellWrapperAlignContentStyle: {},
            };
        }, [cell.align]);

    const {
        cellAlignVerticalContentStyle,
        cellWrapperAlignVerticalContentStyle,
    } = useMemo(() => {
        if (cell.alignVertical && cell.alignVertical !== "CENTER") {
            return {
                cellAlignVerticalContentStyle: {
                    alignItems: cell.alignVertical.toLowerCase(),
                    alignContent:
                        cell.alignVertical === "TOP"
                            ? "flex-start"
                            : "flex-end",
                },
                cellWrapperAlignVerticalContentStyle: {
                    verticalAlign: cell.alignVertical.toLowerCase(),
                },
            };
        }
        return {
            cellAlignVerticalContentStyle: {},
            cellWrapperAlignVerticalContentStyle: {},
        };
    }, [cell.alignVertical]);

    const rotationFlow = cell?.rotationFlow;

    const backgroundImageStyles = useMemo(() => {
        if (rotationFlow?.enabled && rotationFlow?.settings?.rotatable) {
            const currentRotation =
                rotationFlow?.settings?.currentRotation ?? 0;
            const getSupportedImage = rotationFlow?.settings?.images?.find(
                (image) => {
                    if (
                        image?.anglesSupported?.length === 0 ||
                        image?.anglesSupported?.includes(currentRotation)
                    ) {
                        return true;
                    }
                    return false;
                },
            );
            if (getSupportedImage) {
                const optimizedUrl = getSupportedImage.url;
                return {
                    dummyElement: true,
                    backgroundImage: `url(${optimizedUrl})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    transform: `rotate(${currentRotation ?? 0}deg)`,
                };
            }
        }
        if (cell.imageBackground?.enabled) {
            const originalUrl =
                cell.imageBackground?.src?.value ??
                cell.imageBackground?.src?.default;
            const optimizedUrl = originalUrl;
            const size = cell.imageBackground?.size ?? "contain";
            return {
                backgroundImage: `url(${optimizedUrl})`,
                backgroundSize: size, // Adjust as necessary
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            };
        }
        return {};
    }, [
        cell.imageBackground,
        rotationFlow,
        rotationFlow?.settings?.currentRotation,
    ]);

    const fillColorStyle = useMemo(() => {
        if (mainTableSelectionActive) {
            if (isSelected) {
                return {
                    backgroundColor: `rgba(255, 153, 102, ${
                        backgroundImageStyles?.dummyElement ? 0.5 : 1
                    })`,
                };
            }
        }
        if (tagActive) {
            if (isPeeked) {
                return {
                    backgroundColor: "#1790FF",
                };
            }
        }
        if (cell.fillColor?.enabled) {
            return {
                backgroundColor:
                    cell.fillColor.value ?? cell.fillColor.default ?? "#56ccf2",
            };
        }
    }, [
        cell.fillColor,
        tagActive,
        isPeeked,
        mainTableSelectionActive,
        isSelected,
        backgroundImageStyles?.dummyElement,
    ]);

    const paddingStyles = useMemo(() => {
        if (cell.padding?.enabled) {
            if (cell.padding?.custom?.enabled) {
                return {
                    paddingTop: cell.padding?.custom?.top ?? 4,
                    paddingRight: cell.padding?.custom?.right ?? 4,
                    paddingBottom: cell.padding?.custom?.bottom ?? 4,
                    paddingLeft: cell.padding?.custom?.left ?? 4,
                };
            }
            return {
                paddingTop: cell.padding?.vertical ?? 4,
                paddingRight: cell.padding?.horizontal ?? 4,
                paddingBottom: cell.padding?.vertical ?? 4,
                paddingLeft: cell.padding?.horizontal ?? 4,
            };
        } else if (paddingCustomization?.enabled) {
            if (paddingCustomization?.custom?.enabled) {
                return {
                    paddingTop: paddingCustomization?.custom?.top ?? 4,
                    paddingRight: paddingCustomization?.custom?.right ?? 4,
                    paddingBottom: paddingCustomization?.custom?.bottom ?? 4,
                    paddingLeft: paddingCustomization?.custom?.left ?? 4,
                };
            }
            return {
                paddingTop: paddingCustomization?.vertical ?? 4,
                paddingRight: paddingCustomization?.horizontal ?? 4,
                paddingBottom: paddingCustomization?.vertical ?? 4,
                paddingLeft: paddingCustomization?.horizontal ?? 4,
            };
        } else {
            return {
                paddingTop: 4,
                paddingRight: 4,
                paddingBottom: 4,
                paddingLeft: 4,
            };
        }
    }, [cell.padding, paddingCustomization]);
    const cellSpanAttrs = useMemo(() => {
        if (cell.merge?.enabled) {
            return {
                rowSpan: cell.merge.rowSpan,
                colSpan: cell.merge.colSpan,
            };
        }
    }, [cell.merge]);

    const WrapperElem = isDrawing ? "div" : "td";

    const puzzleDropareaEnabledChildStyles =
        puzzleEnabled && cell.dragDrop?.enabled
            ? {
                  padding: 0,
                  display: "grid",
                  gridTemplateRows: "1fr",
              }
            : {};

    const dropArea = cell?.dragDrop?.enabled ? (
        <DropAreaComponent
            dragDropObj={cell.dragDrop}
            rowIndex={rowIndex}
            cellIndex={cellIndex}
            cellFillColorStyle={fillColorStyle}
            shapeStyles={customStyles?.shapeStyles}
            isDrawing={isDrawing}
            isAbsolute={isPuzzleEnabled && isDrawing}
            isPrimaryPuzzlePiece={cell?.dragDrop?.isPrimaryPuzzlePiece}
            isPuzzleEnabled={isPuzzleEnabled}
        />
    ) : null;

    const cellContent = (
        <WrapperElem
            key={cellIndex}
            className="renderer-table-cell-wrapper px-0 py-0"
            style={{
                ...borderStyle,
                ...fillColorStyle,
                ...(!backgroundImageStyles?.dummyElement
                    ? backgroundImageStyles
                    : {}),
                display: cell?.mergeHide ? "none" : "table-cell",
                ...cellWrapperAlignContentStyle,
                ...cellWrapperAlignVerticalContentStyle,
                ...(customStyles?.shapeStyles ?? {}),
                ...(isDrawing
                    ? {
                          height: "fit-content",
                          width: "fit-content",
                          border: "none",
                      }
                    : {}),
                opacity: cell?.hidden ? (isSelected ? 0.5 : 0) : 1,
            }}
            id={`table-cell-${rowIndex}-${cellIndex}`}
            {...cellSpanAttrs}
            onMouseOver={() => handleChangeHover(true)}
            onMouseOut={() => handleChangeHover(false)}
            onClick={() =>
                handleToggleSelection(
                    mainTableSelectionActive ? !isSelected : !isPeeked,
                )
            }
        >
            {backgroundImageStyles?.dummyElement && (
                <>
                    <div
                        style={{
                            ...backgroundImageStyles,
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 0,
                            opacity: 0.5,
                            pointerEvents: "none",
                        }}
                    ></div>
                    {rotationFlow?.settings?.openings?.map((angle) => {
                        const dotStyle = {
                            position: "absolute",
                            width: "8px",
                            height: "8px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 1,
                        };

                        let positionStyle = {};
                        switch (angle % 360) {
                            case 0: // right
                                positionStyle = {
                                    right: 10,
                                    top: "50%",
                                    transform: "translate(50%, -50%)",
                                };
                                break;
                            case 90: // top
                                positionStyle = {
                                    top: 10,
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                };
                                break;
                            case 180: // left
                                positionStyle = {
                                    left: 10,
                                    top: "50%",
                                    transform: "translate(-50%, -50%)",
                                };
                                break;
                            case 270: // bottom
                                positionStyle = {
                                    bottom: 10,
                                    left: "50%",
                                    transform: "translate(-50%, 50%)",
                                };
                                break;
                            default:
                                return null;
                        }

                        return (
                            <div
                                key={angle}
                                style={{
                                    ...dotStyle,
                                    ...positionStyle,
                                }}
                            />
                        );
                    })}
                </>
            )}
            <div
                className="renderer-table-cell"
                style={{
                    ...currentMinMaxWidthStyle,
                    ...cellAlignContentStyle,
                    ...cellAlignVerticalContentStyle,
                    ...currentMinMaxHeightStyle,
                    ...paddingStyles,
                    ...(customStyles?.displayStyles ?? {}),
                    ...(customStyles?.sizeStyles ?? {}),
                    pointerEvents: "none",
                    ...puzzleDropareaEnabledChildStyles,
                    ...(backgroundImageStyles?.dummyElement
                        ? {
                              position: "relative",
                              zIndex: 2,
                          }
                        : {}),
                }}
            >
                {!isDrawing &&
                    (isPuzzleEnabled || isPuzzlePiece) &&
                    !cell?.text?.enabled &&
                    !cell?.dragDrop?.enabled && (
                        <div
                            style={{
                                height: "0px",
                                width: "0px",
                                backgroundColor: "red",
                            }}
                        ></div>
                    )}

                {/* <div
                    style={{
                        fontSize: "10px",
                        position: "absolute",
                        top: 0,
                        left: 0,
                    }}
                >
                    {rowIndex}
                    {cellIndex}
                </div> */}
                {cell.text?.enabled && <Text textObj={cell.text} />}
                {!puzzleEnabled && (
                    <>
                        {cell.image?.enabled && (
                            <ImageComponent imageObj={cell.image} />
                        )}
                        {cell.input?.enabled && (
                            <InputComponent
                                inputObj={cell.input}
                                cellIndex={cellIndex}
                                rowIndex={rowIndex}
                            />
                        )}
                        {cell.dropdown?.enabled && (
                            <DropdownComponent dropdownObj={cell.dropdown} />
                        )}
                        {cell.dragDrop?.enabled &&
                            !isPuzzlePiece &&
                            !(isPuzzleEnabled && isDrawing) &&
                            dropArea}
                    </>
                )}
            </div>
        </WrapperElem>
    );

    if (!isDrawing) {
        return <>{cellContent}</>;
    } else {
        return (
            <CellWrapperComponent
                cellContent={cellContent}
                positionStyles={customStyles?.positionStyles}
                cell={cell}
                borderCustomizations={borderCustomizations}
                showHighlight={false}
                isHovering={isHovering}
                isPeeked={isPeeked}
                sizeStyles={customStyles?.sizeStyles}
                index={cellIndex}
                hideCell={cell?.hidden}
                mainTableSelectionActive={mainTableSelectionActive}
                dropArea={
                    isPuzzleEnabled && isDrawing && !isPuzzlePiece && dropArea
                }
            />
        );
    }
};

export default CellComponent;
